.header-container {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
}

.header-back-container {
    display: flex;
    margin-top: 15px;
    max-width: 50%;
}

.header-back-link {
    text-decoration: none;
}

.header-back-button-text {
    color: black;
    margin-top: 5px;
    font-size: 20px;
    text-align: end;
}

.header-back-arrow {
    padding: 12px 12px 10px 12px;
    font-size: 20px;
    border-radius: 50%;
    background-color: #2672B1;
    color: white;
    margin-left: 10px;
    margin-top: 5px;
}

.secondary-header-back-button {
    margin: 0;
    height: 100%;
    max-height: 60px;
}

.secondary-header-back-line {
    border-radius: 50%;
}

.secondary-header-text {
    display: inline-block;
    font-family: 'Bebas Neue', cursive;
    margin: 0;
    font-size: 5vw;
    padding-top: 16px;
    min-width: 120px;
    font-weight: lighter;
}

.secondary-header-text-container {
    margin-left: 3%;
}

.secondary-header-logo-container {
    width: 40%;
    display: flex;
    flex-direction: row;
}

.secondary-header-logo {
    height: 100%;
    max-height: 114.8469px;
    width: 100%;
    max-width: 200px;
    min-width: 138.98px;
}

@media(min-width: 485px) {
    .secondary-header-text {
        font-size: 25px;
        padding-top: 16px;
        padding-left: 17px;
    }
}

@media(min-width: 530px) {
    .header-back-button-text{
        margin-top: 30px;
    }

    .header-back-arrow {
        margin-top: 20px;
    }
}