/* The Modal (background) */
.modal {
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-container, .fail-modal-container {
	background-color: #fefefe;
	margin: 15% auto; /* 15% from the top and centered */
	padding: 20px;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
	width: fit-content
  }

  .modal-second-header, .fail-modal-second-header {
	  font-weight: 200;
	  padding-top: 15px;
  }

  .fail-modal-second-header {
	  text-align: center;
  }

  .fail-modal-link {
	  text-decoration: none;
  }

  .fail-modal-button {
	-webkit-appearance: none;
    -webkit-border-radius: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    color: white;
    background-color: #2672B1;
    border: none;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
    padding: 15px 30px;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  