.information-page-footer {
    margin-right: auto;
    margin-left: auto;
    height: 25%;
}

.information-page-footer-text {
    font-family: 'Bebas Neue', cursive;
    font-weight: lighter;
    font-size: 30px;
    margin: 0;
    text-align: center;
    padding-top: 10px;
}

.information-page-footer-social-icons {
    color: #2672B1;
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;
    margin-left: auto;
    margin-right: auto;
}

.information-page-social {
    font-size: 50px;
    padding-bottom: 20px;
}

@media (max-width: 500px) {
    .information-page-footer-text {
        font-size: 25px;
    }

    .information-page-social {
        font-size: 40px;
    }
}