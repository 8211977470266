.registration-page {
    border: 0;
    padding-bottom: 25px;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.registration-page-container {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    max-width: 800px;
}

.registration-page-header {
    font-family: 'Bebas Neue', cursive;
    font-size: 50px;
    font-weight: lighter;
    text-align: center;
    margin: 0;
    padding-top: 20px;
}

.registration-page-header-text {
    font-weight: 200;
    font-size: 18px;
    text-align: center;
}

.registration-page-form-header {
    font-size: 28px;
    font-family: 'Bebas Neue', cursive;
    font-weight: lighter;
    margin: 0;
    padding-bottom: 10px;
}

.registration-page-coaching-option-container {
    display: flex;
    background-color: #000000;
    border-radius: 8px;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-around;
    padding: 10px 0px;
}

.registration-pricing-container {
    display: flex;
}

.registration-page-coaching-option {
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    color: white;
    margin: 0px 0 -3px 0;
    font-weight: lighter;
}

.registration-page-monthly {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 200;
}

.registration-page-contact-text, .registration-text-box-header, .registration-page-contact-dropdown {
    font-family: 'Bebas Neue', cursive;
    color: #2672B1;
    margin: 0;
    font-size: 22px;
}

.registration-page-contact-dropdown {
    -moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
    background-color: white;
}


.registration-page-small-container {
    display: flex;
}

.registration-page-input {
    -moz-appearance: none; 
	-webkit-appearance: none; 
    /* background-color: white; */
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    margin-left: 0;
    font-family: 'Bebas Neue', cursive;
    width: 93%;
    border: none;
    min-height: 50px;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
    border-radius: 8px;
}

.registration-page-contact-text-small {
    font-family: 'Bebas Neue', cursive;
    color: #2672B1;
    margin: 0;
    font-size: 22px;
}

.registration-page-input-small {
    margin-bottom: 10px;
    padding-left: 20px;
    margin-left: 0;
    font-family: 'Bebas Neue', cursive;
    width: 75%;
    border: none;
    min-height: 50px;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
    border-radius: 8px;
}

.registration-checkbox-container {
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    margin-top: 0;
    padding: 5px 10px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
}

.registration-checkbox-input {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 0.5px solid black;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.registration-checkbox-input:checked {
    background-color: #2672B1;
}

.registration-checkbox-label {
    letter-spacing: 2px;
    font-weight: 300;
    font-size: 14px;
    margin-left: 5px;
}

.registration-terms-conditions {
    background-color: white;
    border-radius: 8px;
    padding: 0 20px;
    height: 150px;
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
}

.registration-page-submit-button {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    color: white;
    background-color: #2672B1;
    border: none;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
    padding: 15px 30px;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 40px;
}

@media(max-width: 360px) {
    .registration-checkbox-label {
        font-size: 11px;
    }
}

@media(min-width: 500px) {
    .registration-page {
        margin: 0;
    }

    .registration-page-input {
        width: 95%;
    }
}

@media(min-width: 800px) {
    .registration-page-input-small {
        width: 90%;
    }

    .registration-page-contact-text, .registration-page-contact-dropdown {
        display:block    
    }

    .registration-page-input {
        width: 97%;
    }
}