.information-page {
    border: 0;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.information-page-unordered-list {
    list-style: none;
  }
  
.information-page-unordered-list .information-page-li::before {
    content: "\2022"; 
    color: #2672B1; 
    font-weight: bold; 
    display: inline-block; 
    width: 1em; 
    margin-left: -1em;
  }

  .information-page-li, .information-page-star {
      font-size: 5vw;
      font-weight: 200;
      padding-bottom: 5%;
      line-height: 150%;
      padding-right: 20px;
  }

.information-page-li-two {
    font-size: 2vw;
    padding-bottom: 15px;
}

.information-page-container {
    width: 90%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}

.information-page-hero-container {
    display: flex;
    padding-top: 20px;
}

.information-page-hero-image-container-small {
    height: 100%;
}

.information-page-hero-image-container-large {
    display: none;
}

.information-page-hero-image-small {
    width: 100%;
}

.information-page-hero-text-container {
    padding-left: 10px;
    width: 90%;
}

.information-page-hero-text {
    font-family: 'Bebas Neue', cursive;
    font-weight: lighter;
    font-size: 7vw;
    margin: 0;
}

.information-page-how-it-works, .information-page-pricing, .information-page-why-this-program, .information-page-contact {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    margin-top:8%;
    padding-right: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
}

.information-page-subheader {
    font-family: 'Bebas Neue', cursive;
    font-weight: lighter;
    font-size: 10vw;
    margin: 0;
    padding: 10px 0 10px 20px;
}

.information-page-unordered-list {
    margin: 0;
}

.information-page-star {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 0;
}

.information-page-price-option {
    background-color: #FFFFFF;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    margin-bottom: 25px;
    padding-bottom: 20px;
}

.information-page-pricing {
    padding-bottom: 20px;
}

.information-page-pricing-header {
    display: flex;
    justify-content: space-between;
    background-color: #000000;
    border-radius: 8px 8px 0 0;
    padding: 10px 20px;
}

.information-page-pricing-header-text, .information-page-pricing-header-price, .information-page-pricing-header-monthly {
    color: #FFFFFF;
}

.information-page-pricing-header-text, .information-page-pricing-header-price {
    font-family: 'Bebas Neue', cursive;
    font-size: 30px;
    font-weight: lighter;
    margin: 0;
}

.information-page-pricing-header-text {
    width: 50%;
}

.information-page-pricing-header-price {
    padding-top: 10px;
}

.information-page-pricing-unordered-list li {
    font-size: 4.5vw;
}

.information-page-pricing-header-monthly {
    font-weight: 100;
    margin: 0;
}

.information-page-pricing-unordered-list {
    padding-right: 10px;
}

.information-page-pricing-register-button {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: white;
    background-color: #2672B1;
    border: none;
    border-radius: 8px;
    width: 130px;
    padding-top: 9px;
    padding-bottom: 10px;
    font-size: 17px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
}

.information-page-contact {
    padding-right: 20px;
    margin-bottom: 20px;
}

.information-page-contact-form {
    padding: 0 20px 20px 20px;
}

.information-page-contact-text {
    font-family: 'Bebas Neue', cursive;
    color: #2672B1;
    margin: 0;
    font-size: 25px;
}

.information-page-input {
    margin-bottom: 10px;
    font-family: 'Bebas Neue', cursive;
    width: 100%;
    border: none;
    min-height: 50px;
    padding-left: 20px;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
}

.pricing-disclaimer {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.information-page-course-image {
    width: 85%;
    padding-bottom: 20px;
}

.information-page-paragraph-container {
    padding-left: 15px;
}

.pricing-disclaimer-text {
    font-size: 10px;
}

@media(min-width: 500px) {
    .information-page {
        margin: 0;
    }
}

@media(min-width: 768px) {

    .information-page-subheader {
        letter-spacing: 10px;
        margin-right: 0;
    }

    .information-page-how-it-works, .information-page-why-this-program {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .information-page-pricing-header-text, .information-page-pricing-header-price {
        font-size: 60px;
        font-weight: lighter;
    }

    .information-page-pricing-header-monthly {
        font-size: 20px;
    }

    .information-page-unordered-list {
        width: 90%;
    }

    .information-page-li, .information-page-star {
        font-size: 20px;
    }

    .information-page-pricing-unordered-list li {
        font-size: 20px;
    }

    .information-page-footer-social-icons {
        max-width: 768px;
    }
}

@media(min-width: 800px) {
    .information-page-li, .information-page-star {
        font-size: 20px;
    }

    .information-page-hero-image-container-small {
        display: none;
    }

    .information-page-hero-image-container-large {
        display: block;
        height: 100%;
        width: 100%;
    }

    .information-page-hero-image-large {
        width: 100%;
    }

    .information-page-subheader {
        font-size: 60px;
    }
}

@media(min-width: 1000px) {
    .information-page-hero-text {
        font-size: 80px;
    }
}

@media(min-width: 1200px) {
    .information-page-how-it-works, .information-page-why-this-program, .information-page-pricing, .information-page-contact {
        display: flex;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
        padding-top: 40px;
    }

    .information-page-how-it-works, .information-page-why-this-program, .information-page-contact {
        padding-bottom: 0;
    }

    .information-page-pricing {
        height: 500px;
    }

    .information-page-subheader-container {
        width: 40%;
        padding-left: 20px;
    }

    .information-page-subheader {
        width: 400px;
        padding-top: 0;
        font-size: 75px;
    }

    .information-page-pricing-header-text, .information-page-pricing-header-price {
        font-size: 40px;
        font-weight: lighter;
    }

    .information-page-pricing-unordered-list li {
        font-size: 15px;
    }

    .information-page-pricing-unordered-list {
        height: 275px;
    }


    .information-page-price-option {
        width: 380px;
        height: 460px;
        margin-right: 20px;
    }

    .information-page-contact-form {
        width: 100%;
    }

    .information-page-footer-text-container {
        padding: 40px 0 40px 0;
    }

    .information-page-footer-social-icons {
        padding-bottom: 150px;
    }
}