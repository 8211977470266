@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
html, body {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: Inter, "Helvetica Neue";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bebas Neue', sans-serif;
  font-weight: lighter;
  margin: 0;
}

p {
  font-family: Inter, "Helvetica Neue";
  margin: 0;
  font-weight: 300;
}

button {
  -webkit-appearance: none;
}



.header-container {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
}

.header-back-container {
    display: flex;
    margin-top: 15px;
    max-width: 50%;
}

.header-back-link {
    text-decoration: none;
}

.header-back-button-text {
    color: black;
    margin-top: 5px;
    font-size: 20px;
    text-align: end;
}

.header-back-arrow {
    padding: 12px 12px 10px 12px;
    font-size: 20px;
    border-radius: 50%;
    background-color: #2672B1;
    color: white;
    margin-left: 10px;
    margin-top: 5px;
}

.secondary-header-back-button {
    margin: 0;
    height: 100%;
    max-height: 60px;
}

.secondary-header-back-line {
    border-radius: 50%;
}

.secondary-header-text {
    display: inline-block;
    font-family: 'Bebas Neue', cursive;
    margin: 0;
    font-size: 5vw;
    padding-top: 16px;
    min-width: 120px;
    font-weight: lighter;
}

.secondary-header-text-container {
    margin-left: 3%;
}

.secondary-header-logo-container {
    width: 40%;
    display: flex;
    flex-direction: row;
}

.secondary-header-logo {
    height: 100%;
    max-height: 114.8469px;
    width: 100%;
    max-width: 200px;
    min-width: 138.98px;
}

@media(min-width: 485px) {
    .secondary-header-text {
        font-size: 25px;
        padding-top: 16px;
        padding-left: 17px;
    }
}

@media(min-width: 530px) {
    .header-back-button-text{
        margin-top: 30px;
    }

    .header-back-arrow {
        margin-top: 20px;
    }
}
.information-page-footer {
    margin-right: auto;
    margin-left: auto;
    height: 25%;
}

.information-page-footer-text {
    font-family: 'Bebas Neue', cursive;
    font-weight: lighter;
    font-size: 30px;
    margin: 0;
    text-align: center;
    padding-top: 10px;
}

.information-page-footer-social-icons {
    color: #2672B1;
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;
    margin-left: auto;
    margin-right: auto;
}

.information-page-social {
    font-size: 50px;
    padding-bottom: 20px;
}

@media (max-width: 500px) {
    .information-page-footer-text {
        font-size: 25px;
    }

    .information-page-social {
        font-size: 40px;
    }
}
.information-page {
    border: 0;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.information-page-unordered-list {
    list-style: none;
  }
  
.information-page-unordered-list .information-page-li::before {
    content: "\2022"; 
    color: #2672B1; 
    font-weight: bold; 
    display: inline-block; 
    width: 1em; 
    margin-left: -1em;
  }

  .information-page-li, .information-page-star {
      font-size: 5vw;
      font-weight: 200;
      padding-bottom: 5%;
      line-height: 150%;
      padding-right: 20px;
  }

.information-page-li-two {
    font-size: 2vw;
    padding-bottom: 15px;
}

.information-page-container {
    width: 90%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}

.information-page-hero-container {
    display: flex;
    padding-top: 20px;
}

.information-page-hero-image-container-small {
    height: 100%;
}

.information-page-hero-image-container-large {
    display: none;
}

.information-page-hero-image-small {
    width: 100%;
}

.information-page-hero-text-container {
    padding-left: 10px;
    width: 90%;
}

.information-page-hero-text {
    font-family: 'Bebas Neue', cursive;
    font-weight: lighter;
    font-size: 7vw;
    margin: 0;
}

.information-page-how-it-works, .information-page-pricing, .information-page-why-this-program, .information-page-contact {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    margin-top:8%;
    padding-right: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
}

.information-page-subheader {
    font-family: 'Bebas Neue', cursive;
    font-weight: lighter;
    font-size: 10vw;
    margin: 0;
    padding: 10px 0 10px 20px;
}

.information-page-unordered-list {
    margin: 0;
}

.information-page-star {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 0;
}

.information-page-price-option {
    background-color: #FFFFFF;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    margin-bottom: 25px;
    padding-bottom: 20px;
}

.information-page-pricing {
    padding-bottom: 20px;
}

.information-page-pricing-header {
    display: flex;
    justify-content: space-between;
    background-color: #000000;
    border-radius: 8px 8px 0 0;
    padding: 10px 20px;
}

.information-page-pricing-header-text, .information-page-pricing-header-price, .information-page-pricing-header-monthly {
    color: #FFFFFF;
}

.information-page-pricing-header-text, .information-page-pricing-header-price {
    font-family: 'Bebas Neue', cursive;
    font-size: 30px;
    font-weight: lighter;
    margin: 0;
}

.information-page-pricing-header-text {
    width: 50%;
}

.information-page-pricing-header-price {
    padding-top: 10px;
}

.information-page-pricing-unordered-list li {
    font-size: 4.5vw;
}

.information-page-pricing-header-monthly {
    font-weight: 100;
    margin: 0;
}

.information-page-pricing-unordered-list {
    padding-right: 10px;
}

.information-page-pricing-register-button {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: white;
    background-color: #2672B1;
    border: none;
    border-radius: 8px;
    width: 130px;
    padding-top: 9px;
    padding-bottom: 10px;
    font-size: 17px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
}

.information-page-contact {
    padding-right: 20px;
    margin-bottom: 20px;
}

.information-page-contact-form {
    padding: 0 20px 20px 20px;
}

.information-page-contact-text {
    font-family: 'Bebas Neue', cursive;
    color: #2672B1;
    margin: 0;
    font-size: 25px;
}

.information-page-input {
    margin-bottom: 10px;
    font-family: 'Bebas Neue', cursive;
    width: 100%;
    border: none;
    min-height: 50px;
    padding-left: 20px;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
}

.pricing-disclaimer {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.information-page-course-image {
    width: 85%;
    padding-bottom: 20px;
}

.information-page-paragraph-container {
    padding-left: 15px;
}

.pricing-disclaimer-text {
    font-size: 10px;
}

@media(min-width: 500px) {
    .information-page {
        margin: 0;
    }
}

@media(min-width: 768px) {

    .information-page-subheader {
        letter-spacing: 10px;
        margin-right: 0;
    }

    .information-page-how-it-works, .information-page-why-this-program {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .information-page-pricing-header-text, .information-page-pricing-header-price {
        font-size: 60px;
        font-weight: lighter;
    }

    .information-page-pricing-header-monthly {
        font-size: 20px;
    }

    .information-page-unordered-list {
        width: 90%;
    }

    .information-page-li, .information-page-star {
        font-size: 20px;
    }

    .information-page-pricing-unordered-list li {
        font-size: 20px;
    }

    .information-page-footer-social-icons {
        max-width: 768px;
    }
}

@media(min-width: 800px) {
    .information-page-li, .information-page-star {
        font-size: 20px;
    }

    .information-page-hero-image-container-small {
        display: none;
    }

    .information-page-hero-image-container-large {
        display: block;
        height: 100%;
        width: 100%;
    }

    .information-page-hero-image-large {
        width: 100%;
    }

    .information-page-subheader {
        font-size: 60px;
    }
}

@media(min-width: 1000px) {
    .information-page-hero-text {
        font-size: 80px;
    }
}

@media(min-width: 1200px) {
    .information-page-how-it-works, .information-page-why-this-program, .information-page-pricing, .information-page-contact {
        display: flex;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
        padding-top: 40px;
    }

    .information-page-how-it-works, .information-page-why-this-program, .information-page-contact {
        padding-bottom: 0;
    }

    .information-page-pricing {
        height: 500px;
    }

    .information-page-subheader-container {
        width: 40%;
        padding-left: 20px;
    }

    .information-page-subheader {
        width: 400px;
        padding-top: 0;
        font-size: 75px;
    }

    .information-page-pricing-header-text, .information-page-pricing-header-price {
        font-size: 40px;
        font-weight: lighter;
    }

    .information-page-pricing-unordered-list li {
        font-size: 15px;
    }

    .information-page-pricing-unordered-list {
        height: 275px;
    }


    .information-page-price-option {
        width: 380px;
        height: 460px;
        margin-right: 20px;
    }

    .information-page-contact-form {
        width: 100%;
    }

    .information-page-footer-text-container {
        padding: 40px 0 40px 0;
    }

    .information-page-footer-social-icons {
        padding-bottom: 150px;
    }
}
/* The Modal (background) */
.modal {
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-container, .fail-modal-container {
	background-color: #fefefe;
	margin: 15% auto; /* 15% from the top and centered */
	padding: 20px;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content
  }

  .modal-second-header, .fail-modal-second-header {
	  font-weight: 200;
	  padding-top: 15px;
  }

  .fail-modal-second-header {
	  text-align: center;
  }

  .fail-modal-link {
	  text-decoration: none;
  }

  .fail-modal-button {
	-webkit-appearance: none;
    -webkit-border-radius: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    color: white;
    background-color: #2672B1;
    border: none;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
    padding: 15px 30px;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  
.registration-page {
    border: 0;
    padding-bottom: 25px;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.registration-page-container {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    max-width: 800px;
}

.registration-page-header {
    font-family: 'Bebas Neue', cursive;
    font-size: 50px;
    font-weight: lighter;
    text-align: center;
    margin: 0;
    padding-top: 20px;
}

.registration-page-header-text {
    font-weight: 200;
    font-size: 18px;
    text-align: center;
}

.registration-page-form-header {
    font-size: 28px;
    font-family: 'Bebas Neue', cursive;
    font-weight: lighter;
    margin: 0;
    padding-bottom: 10px;
}

.registration-page-coaching-option-container {
    display: flex;
    background-color: #000000;
    border-radius: 8px;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-around;
    padding: 10px 0px;
}

.registration-pricing-container {
    display: flex;
}

.registration-page-coaching-option {
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    color: white;
    margin: 0px 0 -3px 0;
    font-weight: lighter;
}

.registration-page-monthly {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 200;
}

.registration-page-contact-text, .registration-text-box-header, .registration-page-contact-dropdown {
    font-family: 'Bebas Neue', cursive;
    color: #2672B1;
    margin: 0;
    font-size: 22px;
}

.registration-page-contact-dropdown { 
	-webkit-appearance: none; 
	appearance: none;
    background-color: white;
}


.registration-page-small-container {
    display: flex;
}

.registration-page-input {
    -moz-appearance: none; 
	-webkit-appearance: none; 
    /* background-color: white; */
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    margin-left: 0;
    font-family: 'Bebas Neue', cursive;
    width: 93%;
    border: none;
    min-height: 50px;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
    border-radius: 8px;
}

.registration-page-contact-text-small {
    font-family: 'Bebas Neue', cursive;
    color: #2672B1;
    margin: 0;
    font-size: 22px;
}

.registration-page-input-small {
    margin-bottom: 10px;
    padding-left: 20px;
    margin-left: 0;
    font-family: 'Bebas Neue', cursive;
    width: 75%;
    border: none;
    min-height: 50px;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
    border-radius: 8px;
}

.registration-checkbox-container {
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    margin-top: 0;
    padding: 5px 10px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
}

.registration-checkbox-input {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 0.5px solid black;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.registration-checkbox-input:checked {
    background-color: #2672B1;
}

.registration-checkbox-label {
    letter-spacing: 2px;
    font-weight: 300;
    font-size: 14px;
    margin-left: 5px;
}

.registration-terms-conditions {
    background-color: white;
    border-radius: 8px;
    padding: 0 20px;
    height: 150px;
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
}

.registration-page-submit-button {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    color: white;
    background-color: #2672B1;
    border: none;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px ;
    padding: 15px 30px;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 40px;
}

@media(max-width: 360px) {
    .registration-checkbox-label {
        font-size: 11px;
    }
}

@media(min-width: 500px) {
    .registration-page {
        margin: 0;
    }

    .registration-page-input {
        width: 95%;
    }
}

@media(min-width: 800px) {
    .registration-page-input-small {
        width: 90%;
    }

    .registration-page-contact-text, .registration-page-contact-dropdown {
        display:block    
    }

    .registration-page-input {
        width: 97%;
    }
}
