@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

html, body {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: Inter, "Helvetica Neue";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bebas Neue', sans-serif;
  font-weight: lighter;
  margin: 0;
}

p {
  font-family: Inter, "Helvetica Neue";
  margin: 0;
  font-weight: 300;
}

button {
  -webkit-appearance: none;
}
